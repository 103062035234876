<template>
  <div>
    <vx-card>
      <vs-tabs>
        <vs-tab label="Processing Invoice">
          <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
                style="font-size: 15px"
                >Can-ID</vs-col
              >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              >
                <vs-input
                  class="w-full"
                  placeholder="Can-ID"
                  v-model="PI_identity"
                />
              </vs-col>
            </vs-row>
            <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
                style="font-size: 15px"
                >Course</vs-col
              >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              >
                <vs-input
                  class="w-full"
                  placeholder="Course"
                  v-model="PI_course"
                />
              </vs-col>
            </vs-row>
            <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
                style="font-size: 15px"
                >Amount</vs-col
              >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              >
                <vs-input
                  class="w-full"
                  placeholder="Amount"
                  v-model="PI_amount"
                  v-on:keypress="isNumber(event)"
                />
              </vs-col>
            </vs-row>
            <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="right"
                vs-w="12"
                style="font-size: 15px"
                >
                  <vs-button color="dark" type="filled" @click="saveProcessingInvoice">Save</vs-button>
                </vs-col>
            </vs-row>
        </vs-tab>
        <vs-tab label="Credit Note">
          <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
                style="font-size: 15px"
                >Can-ID</vs-col
              >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              >
                <vs-input
                  class="w-full"
                  placeholder="Can-ID"
                  v-model="CN_identity"
                />
              </vs-col>
            </vs-row>
            <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
                style="font-size: 15px"
                >Course</vs-col
              >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              >
                <vs-input
                  class="w-full"
                  placeholder="Course"
                  v-model="CN_course"
                />
              </vs-col>
            </vs-row>
            <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
                style="font-size: 15px"
                >Amount</vs-col
              >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              >
                <vs-input
                  class="w-full"
                  placeholder="Amount"
                  v-model="CN_amount"
                  v-on:keypress="isNumber(event)"
                />
              </vs-col>
            </vs-row>
            <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
                style="font-size: 15px"
              ></vs-col>
              
            </vs-row>
            <vs-row class="gaps" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="right"
                vs-w="12"
                style="font-size: 15px"
                >
                  <vs-button color="dark" type="filled" @click="saveCreditNote">Save</vs-button>
                </vs-col>
            </vs-row>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>
import constants from "../../constants.json";
// import _ from "lodash";
import axios from "axios";

export default {
  data() {
    return {
      event: "",
      PI_identity: "",
      PI_course: "",
      PI_amount: null,
      CN_identity: "",
      CN_course: "",
      CN_amount: null,
    };
  },
  methods: {
   saveProcessingInvoice(){
     let obj = {
        identity: this.PI_identity,
        course: this.PI_course,
        amount: this.PI_amount,
      };
     console.log("ProcessingInvoice",this.PI_identity, this.PI_course, this.PI_amount);
     axios
        .post(`${constants.SERVER_API}createAProcessingFeeInvoiceInQB`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
         console.log("Processing invoice response", response);
          this.$vs.notify({
          
          text: response.data.message,
          color: "danger",
        });
        })
        .catch((error) => {
          this.handleError(error);
        });

   },
   saveCreditNote(){
     console.log("CreditNote",this.CN_identity, this.CN_course, this.CN_amount);
     let obj = {
        identity: this.CN_identity,
        course: this.CN_course,
        amount: this.CN_amount,
      };
      axios
        .post(`${constants.SERVER_API}createASubventionInQB`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
         console.log("Processing invoice response", response);
          this.$vs.notify({
          
          text: response.data.message,
          color: "danger",
        });
        })
        .catch((error) => {
          this.handleError(error);
        });
   },
   isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        this.$vs.notify({
          text: "Please Enter Amount",
          color: "danger",
        });
      } else {
        return true;
      }
    } 
  },
};
</script>